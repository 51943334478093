import axios from 'axios';
import { replace } from 'connected-react-router';
import { createSignature, getCertificate } from 'crypto-pro';
import _ from 'lodash';
import Querystring from 'querystring-es3';
import { createAction } from 'redux-actions';

import * as api from '../api';
import { unsubscribeFromUpdates } from '../lib/mqtt';
import { fetchUserDetails } from './user';

export const loginRequest = createAction('REQUEST_LOGIN');
export const loginSuccess = createAction('SUCCESS_LOGIN');
export const loginFailure = createAction('FAILURE_LOGIN');

export const login = ({ id, password }): any => async (dispatch: any): Promise<any> => {
  dispatch(loginRequest({ id, password }));

  try {
    const body = Querystring.stringify({
      id,
      password,
    });

    const { data: userData } = await axios.post(
      api.login(),
      body,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    const { data: { token, user } } = userData;
    user.isAuthenticated = true;

    window.localStorage.setItem('token', token);
    window.localStorage.setItem('user', JSON.stringify(user));

    dispatch(fetchUserDetails({ id }));

    dispatch(loginSuccess({ user }));
    dispatch(replace('/'));
  } catch (error: any) {
    dispatch(loginFailure(error.toString()));
  }
};

export const setUserCertificateSuccess = createAction('SUCCESS_USER_CERTIFICATE_SET');

export const setUserCertificate = (
  {
    thumbprint,
  },
): any => async (dispatch: any): Promise<any> => {
  try {
    if (!thumbprint) return;

    const base64 = Buffer.from('secret, hurr', 'latin1').toString('base64');
    await createSignature(thumbprint, base64, true);
    window.localStorage.setItem('thumbprint', thumbprint);
    dispatch(setUserCertificateSuccess({ thumbprint }));
  } catch (error) {
    console.warn({ error });
    window.localStorage.removeItem('thumbprint');
  }
};

export const certAuth = ({ thumbprint }): any => async (dispatch: any): Promise<any> => {
  dispatch(loginRequest({
    thumbprint,
  },));

  try {
    const cert = await getCertificate(thumbprint);
    const signSerialNumber = await cert.getCadesProp('SerialNumber');
    const owner = await cert.getOwnerInfo();
    const lastName = _.find(owner, ['title', 'Фамилия']).description;
    const itn = _.find(owner, ['title', 'ИНН']).description;
    const insuranceNumber = _.find(owner, ['title', 'СНИЛС']).description;
    const { validFrom: signValidFrom, validTo: signValidTo } = cert;

    const body = Querystring.stringify({
      itn,
      insuranceNumber,
      lastName,
      signSerialNumber,
      signValidFrom,
      signValidTo,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const resp = await axios.post(api.certAuth(), body, config);
    const { data: { token, user } } = resp.data;

    user.isAuthenticated = true;

    window.localStorage.setItem('token', token);
    window.localStorage.setItem('user', JSON.stringify(user));

    dispatch(fetchUserDetails({ id: user.id }));

    dispatch(setUserCertificate({ thumbprint }));
    dispatch(loginSuccess({ user }));
    dispatch(replace('/exams'));
  } catch (error: any) {
    dispatch(loginFailure(error.toString()));
  }
};

export const logoutRequest = createAction('REQUEST_LOGOUT');
export const logoutSuccess = createAction('SUCCESS_LOGOUT');
export const logoutFailure = createAction('FAILURE_LOGOUT');

export const logout = (): any => async (dispatch: any, getState): Promise<any> => {
  dispatch(logoutRequest());

  try {
    const config = {
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
    };
    await axios.post(api.logout(), {}, config);

    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('thumbprint');

    const { contracts } = getState().contracts.entities;

    await unsubscribeFromUpdates({ contracts });
    dispatch(logoutSuccess());

    dispatch(replace('/login'));
  } catch (error) {
    dispatch(logoutFailure(error));
  }
};
