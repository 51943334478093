import { createAction } from 'redux-actions';

import { fetchEmployees as fetch } from '../lib/fetch';

export const fetchEmployeesRequest = createAction('REQUEST_FETCH_EMPLOYEES');
export const fetchEmployeesSuccess = createAction('SUCCESS_FETCH_EMPLOYEES');
export const fetchEmployeesFailure = createAction('FAILURE_FETCH_EMPLOYEES');


export const fetchEmployees = ({ contracts }): any => async (dispatch: any): Promise<any> => {
  dispatch(fetchEmployeesRequest({ contracts }));
  try {
    const employees = await fetch({ contracts });
    dispatch(fetchEmployeesSuccess({ employees }));
  } catch (error) {
    dispatch(fetchEmployeesFailure(error));
  }
};
