import { createAction } from 'redux-actions';

import { fetchUserContracts } from '../lib/fetch';
import { subscribeToUpdates } from '../lib/mqtt';

export const fetchContractsRequest = createAction('REQUEST_FETCH_CONTRACTS');
export const fetchContractsSuccess = createAction('SUCCESS_FETCH_CONTRACTS');
export const fetchContractsFailure = createAction('FAILURE_FETCH_CONTRACTS');

export const fetchContracts = ({ user }): any => async (dispatch: any): Promise<any> => {
  dispatch(fetchContractsRequest({ user }));
  try {
    const contracts = await fetchUserContracts(user);
    dispatch(fetchContractsSuccess({ contracts }));
    await subscribeToUpdates({ contracts });
  } catch (error) {
    dispatch(fetchContractsFailure(error));
  }
};
