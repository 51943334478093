import { createSelector } from 'reselect';

const getCerts = (state: any): any => state.certs;

export const certsSelector = createSelector(
  getCerts,
  (certs) => Object.values(certs),
);

export const certsGettingStateSelector = (state) => state.certsGettingState;

export const currentNurseModeSelector = (state) => state.currentNurseMode;

export const currentCertificateSelector = (state) => state.currentCertificate;
