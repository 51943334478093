import _currentExam from './currentExam';
import exams from './exams';
import employees from './employees';

export * as certs from './certs';
export * as contracts from './contracts';
export * as employees from './employees';
export * as user from './user';
export const currentExam = _currentExam;

export default { exams, employees };
