import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

const lastExamsByTypeIteratee = (acc, exams) => {
  const sortedExams = _.sortBy(exams, 'updatedAt');
  const lastExam = sortedExams[sortedExams.length];
  return { ...acc, [lastExam.id]: lastExam };
};

const groupedByDateIteratee = (acc, allExamsOnDate) => {
  const examsGroupedByType = _.groupBy(allExamsOnDate, 'ExamType');
  const lastExams = _.transfrom(examsGroupedByType, lastExamsByTypeIteratee, {});
  return { ...acc, ...lastExams };
};

const groupedByEmployeeIteratee = (acc, employeeExams) => {
  const examsGroupedByDate = _.groupBy(employeeExams, (exam) => moment(exam.updatedAt).format('DD/MM/YYYY'));
  const groupedExams = _.transform(examsGroupedByDate, groupedByDateIteratee, {});
  return { ...acc, ...groupedExams };
};

const getExams = (
  state,
  props,
) => {
  const { entities: { exams } } = state.exams;

  const {
    filter = { status: [] },
    journalMode = false,
  } = props;

  const {
    status, contractIds, examTypeId, startOfDay, endOfDay, allowance, ids, employeeIds, NurseId,
  } = filter;

  const filteredExams = _.filter(exams, (exam) => {
    const idsMatch = _.isNil(ids) || _.some(ids, (id) => exam.id === id);
    const matchContract = _.isNil(contractIds)
      || contractIds.length === 0
      || _.some(contractIds, (id) => exam.Contract === id);
    const matchEmployee = _.isNil(employeeIds)
      || employeeIds.length === 0
      || _.some(employeeIds, ({ id }) => exam.Worker === id);
    const matchExamType = _.isNil(examTypeId) || exam.ExamType === examTypeId;
    const matchNurseId = _.isNil(NurseId) || exam.NurseId === NurseId;
    const statusMatch = _.isNil(status) || _.some(status, (s) => exam.status.includes(s));
    const allowanceMatch = _.isNil(allowance) || exam.allowance === allowance;
    const dateMatch = _.isNil(startOfDay) || _.isNil(endOfDay)
      || moment.utc(exam.updatedAt).zone(-exam.terminal_local_time_offset / 60).isBetween(startOfDay, endOfDay);

    return idsMatch
      && matchContract
      && matchExamType
      && matchNurseId
      && statusMatch
      && dateMatch
      && allowanceMatch
      && matchEmployee;
  });

  if (journalMode) {
    const examsGroupedByEmployee = _.groupBy(filteredExams, 'Worker');
    const result = _.transform(examsGroupedByEmployee, groupedByEmployeeIteratee, {});
    return result;
  }

  return filteredExams;
};

export const examsSelector = createSelector(
  getExams,
  (exams) => Object.values(exams),
);

export default examsSelector;
