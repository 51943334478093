import _ from 'lodash';
import { createSelector } from 'reselect';

export const getEmployees = (
  state,
  props,
) => {
  const { entities: { users } } = state.employees;

  const {
    filter = { ids: [], fullName: '' },
  } = props;

  const {
    ids,
    fullName,
  } = filter;

  const filteredEmployees = _.filter(users, (user) => {
    const idsMatch = _.isNil(ids) || ids.length === 0 || _.some(ids, (id) => user.id === id);

    const currentEmployeeFullName = [user.lastName, user.firstName, user.patronymic].join(' ')
      .trim()
      .toLowerCase();

    const fullNameMatch = _.isNil(fullName)
      || fullName.length === 0
      || (currentEmployeeFullName.includes(fullName.trim().toLowerCase())
      );
    
    return idsMatch && fullNameMatch;
  });

  return filteredEmployees;
};

export const employeesSelector = createSelector(
  getEmployees,
  (employees) => Object.values(employees),
);

export default employeesSelector;
