import moment from 'moment';
import { handleActions } from 'redux-actions';

import * as actions from '../../actions';

export const statisticPage = handleActions({
  [actions.setDate.toString()](_state, { payload: { date } }) {
    return { date };
  },
}, { date: moment() });

export default { statisticPage };
