import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button, Col, Form, Input, Row,
} from 'antd';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import connect from '../../connect';

@(withRouter as any)
@connect(null) class NormalLoginForm extends React.Component<any, any> {
  handleSubmit = (values: any): any => {
    const { login } = this.props;
    login(values);
  };

  render() {
    return (
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={this.handleSubmit}
      >
        <Form.Item
          name="id"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите Ваш идентификатор!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Идентификатор" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите Ваш пароль!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Войти
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const LoginPage = () => (
  <Row justify="center">
    <Col>
      <NormalLoginForm />
      <div><Link to="/digital-sign-login">Вход с помощью электронной подписи</Link></div>
    </Col>
  </Row>
);

export default LoginPage;
