import 'moment/dist/locale/ru';
import _ from 'lodash';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Select, Input, Typography, Divider } from 'antd';
import moment from 'moment';
import React from 'react';

import connect from '../../connect';
import { contractsSelector } from '../../selectors/contracts';
import ExamsTable from '../ExamsTable';
import { employeesSelector } from '@/selectors/employees';

moment.locale('ru');

const { RangePicker } = DatePicker;
const { Search } = Input;

const mapsStateToProps = (state: any) => {
  const fetchingExamsStatus = state.exams;

  const { dateRange, contractId, employeeFullName, fetchingJournalStatus, examTypeId } = state.ui.journalPage;

  const props = {
    user: state.user,
    contracts: contractsSelector(state),
    dateRange,
    contractId,
    examTypeId,
    employees: employeesSelector(state, { filter: { fullName: employeeFullName } }),
    employeeFullName,
    fetchingJournalStatus
  };
  return props;
};


@connect(mapsStateToProps) class JournalPage extends React.Component<any, any> {
  changeRange = (dateRange) => {
    const { setDates, fetchExams, contracts } = this.props;
    setDates(dateRange);

    if (!dateRange || !dateRange[0] || !dateRange[1]) return;

    const startOfDay = moment(dateRange[0]).startOf('day');
    const endOfDay = moment(dateRange[1]).endOf('day');

    fetchExams({
      contracts: [],
      startOfDay: startOfDay.format("YYYY-MM-DDT00:00:00"),
      endOfDay: endOfDay.format("YYYY-MM-DDT23:59:59"),
    });
  }

  render() {
    const {
      changeJournalData,
      fetchJournal,
      setContract,
      setEmployeeFullName,
      setExamType,
      employees,
      setDates,
    } = this.props;

    const {
      dateRange,
      contracts,
      contractId,
      employeeFullName,
      fetchingJournalStatus,
      examTypeId,
    } = this.props;

    const disabledDate = (current) => {
      if (!dateRange) {
        return false;
      }
      const tooLate = dateRange[0] && current.diff(dateRange[0], 'days') >= 31;
      const tooEarly = dateRange[1] && dateRange[1].diff(current, 'days') >= 31;
      return !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open) => {
      if (open) {
        setDates([null, null]);
      }
    };

    return (
      <div style={{ backgroundColor: 'white' }}>

        <Row gutter={16}>
          <Col span={10}>
            <Typography.Title level={5}>Работник</Typography.Title>
            <Search
              placeholder='Введите Ф.И.О. работника..'
              allowClear

              defaultValue={employeeFullName}
              onSearch={(val) => setEmployeeFullName({ employeeFullName: val })} />
          </Col>
          <Col span={6}>
            <Typography.Title level={5}>Тип осмотра</Typography.Title>
            <Select
              allowClear
              placeholder="Выберите тип"
              defaultValue={examTypeId}
              style={{ width: 300 }}
              onChange={(examTypeId) => setExamType({ examTypeId })}
              options={[
                { value: 1, label: 'предрейсовый' },
                { value: 2, label: 'послерейсовый' },
                { value: 3, label: 'предсменный' },
                { value: 4, label: 'послесменный' },
                { value: 5, label: 'в течение рабочего дня (смены)' },
              ]}
            />
          </Col>
          <Col>
            <Typography.Title level={5}>Экспорт</Typography.Title>
            <Button type="primary" icon={<DownloadOutlined />} onClick={() => {
              const d = {
                contracts: _.isNil(contractId) ? [] : [{ id: contractId }],
                startOfDay: moment(dateRange[0]).startOf('day'),
                endOfDay: moment(dateRange[1]).endOf('day'),
                Worker: employeeFullName.length == 0
                  ? null
                  : { id: employees.map(({ id }) => id) },
                examTypeId,
                type: 'pdf',
              };
              fetchJournal(d);
            }}
              loading={fetchingJournalStatus.pdf === 'requested'}
            >PDF</Button><Divider type='vertical' plain></Divider>
            <Button type="primary" icon={<DownloadOutlined />} onClick={() => {
              const d = {
                contracts: _.isNil(contractId) ? [] : [{ id: contractId }],
                startOfDay: moment(dateRange[0]).startOf('day'),
                endOfDay: moment(dateRange[1]).endOf('day'),
                Worker: employeeFullName.length == 0
                  ? null
                  : { id: employees.map(({ id }) => id) },
                examTypeId,
                type: 'xlsx'
              };
              fetchJournal(d);
            }}
              loading={fetchingJournalStatus.xlsx === 'requested'}>XLSX</Button>
          </Col>
        </Row>
        <Row align="middle" gutter={16}>
          <Col span={10}>
            <Typography.Title level={5}>Место проведения осмотра</Typography.Title>
            <Select
              allowClear
              showSearch
              placeholder="Выберите место"
              optionFilterProp="children"
              defaultValue={contractId}
              style={{ width: 500 }}
              onChange={(contractId) => setContract({ contractId })}
              filterOption={(input: any, option: any): boolean =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={contracts.map(c => ({ value: c.id, label: c.name }))}
            />
          </Col>
          <Col span={6}>
            <Typography.Title level={5}>Интервал</Typography.Title>
            <RangePicker
              value={dateRange}
              disabledDate={disabledDate}
              style={{ width: 300 }}
              onCalendarChange={(val) => {
                this.changeRange(val);
              }}
              onOpenChange={onOpenChange}
            />
          </Col>
        </Row>
        <Divider></Divider>
        <Row>
          <Col span={24}>
            <ExamsTable
              filter={{
                status: ['signed'],
                startOfDay: dateRange && dateRange[0] && moment(dateRange[0]).startOf('day'),
                endOfDay: dateRange && dateRange[1] && moment(dateRange[1]).endOf('day'),
                contractIds: _.isNil(contractId) ? contracts.map(c => c.id) : [contractId],
                employeeIds: employees.map(id => id),
                examTypeId,
              }}
              changeCurrentDataSource={({ data }) => changeJournalData({ data })}
            />
          </Col>
        </Row>

      </div >
    );
  }
}

export default JournalPage;
