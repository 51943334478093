import _ from 'lodash';
import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

import * as actions from '../actions';
import * as schemas from './schemas';

const defaultState = Immutable({
  entities: {},
  employeesFetchingStatus: '',
});

export const employees = handleActions({
  [actions.fetchEmployeesSuccess.toString()](state, { payload }: any) {
    const { entities } = normalize(payload.employees, schemas.userListSchema);
    return state.merge({ employeesFetchingStatus: 'successed', entities: _.omit(entities, 'subdivisions') });
  },
  [actions.logoutSuccess.toString()]() {
    return defaultState;
  },
  [actions.fetchEmployeesRequest.toString()](state) {
    return state.merge({ employeesFetchingStatus: 'requested' });
  },
  [actions.fetchEmployeesFailure.toString()](state) {
    return state.merge({ employeesFetchingStatus: 'failed' });
  },
}, defaultState);

export default { employees };
