import { subscribeToUpdates } from './../lib/mqtt';
import { employees } from './../reducers/employees';
import axios from 'axios';
import { createAction } from 'redux-actions';
import moment from 'moment';
import * as api from '../api';
import { fetchUserContracts, fetchExams, fetchEmployees } from '../lib/fetch';
import { fetchContractsSuccess } from './contracts';
import { fetchEmployeesSuccess } from './employees';
import { fetchExamsSuccess } from './exams';


export const fetchUserDetailsRequest = createAction('REQUEST_FETCH_USER_INFO');
export const fetchUserDetailsSuccess = createAction('SUCCESS_FETCH_USER_INFO');
export const fetchUserDetailsFailure = createAction('FAILURE_FETCH_USER_INFO');

export const fetchUserDetails = ({ id }): any => async (dispatch: any): Promise<any> => {
  dispatch(fetchUserDetailsRequest());
  try {
    const resp = await axios.get(api.user(id));
    const { data: user } = resp.data;
    const contracts = await fetchUserContracts(user);
    const employees = await fetchEmployees({ contracts: [] });
    const startOfDay = moment().startOf('day');
    const endOfDay = moment().endOf('day');
    const exams = await fetchExams({ contracts: [], startOfDay, endOfDay });
    dispatch(fetchContractsSuccess({ contracts }));
    dispatch(fetchEmployeesSuccess({ employees }));
    dispatch(fetchExamsSuccess({ exams }));
    dispatch(fetchUserDetailsSuccess({ user }));
    await subscribeToUpdates({ contracts });
  } catch (error: any) {
    dispatch(fetchUserDetailsFailure(error.toString()));
  }
};

export const updateCurrentMode = createAction('CURRENT_MODE_UPDATE');
