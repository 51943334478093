import { contract } from './../../routes_';
import moment from 'moment';
import { handleActions } from 'redux-actions';

import * as actions from '../../actions';

const setStartOfDay = (m) => m.startOf('day');
const setEndOfDay = (m) => m.endOf('day');

const now = moment();

const defaultState = {
  dateRange: [setStartOfDay(moment()), setEndOfDay(moment())],
  fetchingJournalStatus: {
    pdf: '',
    xlsx: '',
  },
  contractId: null,
  examTypeId: null,
  employeeFullName: '',
  data: [],
};

export const journalPage = handleActions({
  [actions.setDates.toString()](state, { payload: dates }: any) {
    return {
      ...state,
      dateRange: dates,
    };
  },
  [actions.setContract.toString()](state, { payload: { contractId } }) {
    return {
      ...state,
      contractId
    };
  },
  [actions.setExamType.toString()](state, { payload: { examTypeId } }) {
    return {
      ...state,
      examTypeId
    };
  },
  [actions.setEmployeeFullName.toString()](state, { payload: { employeeFullName } }) {
    return {
      ...state,
      employeeFullName,
    };
  },
  [actions.fetchJournalRequest.toString()](state, { payload: { type } }: any) {
    const newState = state;
    newState.fetchingJournalStatus[type] = 'requested';
    return { ...newState };
  },
  [actions.fetchJournalFailure.toString()](state, { payload: { type } }: any) {
    const newState = state;
    newState.fetchingJournalStatus[type] = 'failed';
    return { ...newState };
  },
  [actions.fetchJournalSuccess.toString()](state, { payload: { type } }: any) {
    const newState = state;
    newState.fetchingJournalStatus[type] = 'successed';
    return { ...newState };
  },
  [actions.changeJournalData.toString()](state, { payload: { data } }) {
    return { ...state, data };
  },
}, defaultState);

export default { journalPage };
