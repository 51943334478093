import './App.css';
import './index.css';
import 'antd/dist/antd.css';

import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { registerObserver } from 'react-perf-devtool';

import * as actions from './actions';
import App from './App';
import mqttSocket from './lib/mqtt';
import * as serviceWorker from './serviceWorker';
import createStore, { history } from './store';

registerObserver();

const store = createStore();

const dispatchers = {
  active: (data) => store.dispatch(actions.addExam(data)),
  pendingAllowance: (data) => store.dispatch(actions.pendingAllowance(data)),
  pendingEmployeeSign: (data) => store.dispatch(actions.changeAllowanceSuccess(data)),
  pendingNurseSign: (data) => store.dispatch(actions.signExam(data)),
  signed: (data) => store.dispatch(actions.signExamSuccess(data)),
  canceled: (data) => store.dispatch(actions.updateExam(data)),
};

const getDispatcher = (data) => {
  const { exam: { status } } = data;
  console.log('received in dispatcher %o', { status });
  if (!status || !Object.keys(dispatchers).includes(status)) {
    return;
  }

  dispatchers[status](data);
};

mqttSocket.on('message', (topic, message) => {
  const data = JSON.parse(message.toString());
  console.log('Received message %o status = ', topic, data.exam.status, 'id = ', data.exam.id);
  getDispatcher(data);
});

ReactDOM.render(React.createElement(App, { store, history }), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
