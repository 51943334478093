import mqtt from 'async-mqtt';
import _ from 'lodash';

import { MQTT_HOST, MQTT_PORT, MQTT_PROTOCOL } from '../config';
import * as topics from '../topics';

const clientId = MQTT_HOST + '-' + Math.random().toString(16).substr(2, 8);
console.log({ MQTT_HOST, MQTT_PORT, MQTT_PROTOCOL, clientId });

const socket = mqtt.connect({
  hostname: MQTT_HOST,
  port: MQTT_PORT,
  protocol: MQTT_PROTOCOL,
  username: 'mqttest',
  password: 'Fucker86',
  clientId,
  keepalive: 0,
});


socket.on('connect', () => console.log('mqtt connected'));
socket.on('error', (err) => console.log('mqtt error', { err }));


export const subscribeToUpdates = async ({ contracts }) => {
  const nurses = _.chain(contracts)
    .map(({ Nurses }) => Nurses)
    .flatten()
    .filter(n => !_.isNil(n))
    .uniqBy(n => n.id)
    .map(({ id }) => id)
    .value();
  const promises = nurses.map((nurseId) => {
    const topic = topics.exams(nurseId);
    return socket.subscribe(topic, { qos: 2 });
  });
  await Promise.all(promises);
};

export const unsubscribeFromUpdates = async ({ contracts }) => {
  const nurses = _.chain(contracts)
    .map(({ Nurses }) => Nurses)
    .flatten()
    .filter(n => !_.isNil(n))
    .uniq()
    .value();
  const promises = nurses.map((nurseId) => {
    const topic = topics.exams(nurseId);
    return socket.unsubscribe(topic);
  });
  await Promise.all(promises);
};

export default socket;
