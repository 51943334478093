import { Button, Result } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="Извините, запрошенная Вами страница не существует"
    extra={<Link to="/"><Button type="primary">Назад</Button></Link>}
  />
);

export default NotFoundPage;
