import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

import * as actions from '../actions';
import * as schemas from './schemas';

const defaultState = Immutable({
  entities: {},
  fetchingContractsStatus: '',
});

export const contracts = handleActions({
  [actions.logoutSuccess.toString()]() {
    return defaultState;
  },
  [actions.fetchContractsRequest.toString()](state) {
    return state.merge({ fetchingContractsStatus: 'requested' });
  },
  [actions.fetchContractsSuccess.toString()](state, { payload }) {
    const { entities } = normalize(payload.contracts, schemas.contractListSchema);
    return state.merge({ fetchingContractsStatus: 'successed', entities });
  },
  [actions.fetchContractsFailure.toString()](state) {
    return state.merge({ fetchingContractsStatus: 'failed' });
  },
}, defaultState);

export default {
  contracts,
};
