import {
  Button, Col, Layout, Row, Typography,
} from 'antd';
import React from 'react';

import connect from '../../connect';
import * as selectors from '../../selectors';

const mapStateToProps = (state) => {
  const props = {
    isAuthenticated: selectors.user.isAuthenticated(state),
    user: state.user,
  };
  return props;
};

@connect(mapStateToProps)
class Header extends React.Component<any> {
  render() {
    const { logout } = this.props;
    const { isAuthenticated, style, user } = this.props;
    return (
      <Layout.Header style={style}>
        <Row style={{ margin: '0 16px' }} justify="space-between" align="middle" wrap={false}>
          <Col>
            <Typography.Title level={3}><img src="/medregistr_logo.png" style={{ maxHeight: '50px' }} alt="Медрегистр логотип" /></Typography.Title>
          </Col>
          <Col>
            {isAuthenticated
              ? (
                <div>
                  {[user.lastName, user.firstName, user.patronymic, ' '].join(' ')}
                  <Button onClick={logout}>Выйти</Button>
                </div>
              )
              : null}
          </Col>
        </Row>
      </Layout.Header>
    );
  }
}

export default Header;
