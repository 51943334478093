import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

import * as actions from '../actions';
import * as schemas from './schemas';

const defaultState = Immutable({
  approvalMode: 'manual',
  fetchingUserDetailsStatus: '',
});

export const user = handleActions({
  [actions.loginSuccess.toString()](state, { payload }: any) {
    const normalized = normalize(payload.user, schemas.loggedUserSchema);
    const userId = normalized.result;
    return state.merge(normalized.entities?.userInfo?.[userId]);
  },
  [actions.logoutSuccess.toString()]() {
    return defaultState;
  },
  [actions.fetchUserDetailsSuccess.toString()](state, { payload }: any) {
    const normalized = normalize(payload.user, schemas.loggedUserSchema);
    const userId = normalized.result;
    return state.merge(normalized.entities?.userInfo?.[userId]);
  },
  [actions.updateCurrentMode.toString()](state, { payload: { mode } }) {
    return state.merge({ approvalMode: mode });
  },
  [actions.fetchUserDetailsRequest.toString()](state) {
    return state.merge({ fetchingUserDetailsStatus: 'requested' });
  },
  [actions.fetchUserDetailsSuccess.toString()](state) {
    return state.merge({ fetchingUserDetailsStatus: 'successed' });
  },
  [actions.fetchUserDetailsFailure.toString()](state) {
    return state.merge({ fetchingUserDetailsStatus: 'failed' });
  },
}, defaultState);

export default {
  user,
};
