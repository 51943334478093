import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {
  applyMiddleware, compose, createStore, Store,
} from 'redux';
import thunk from 'redux-thunk';

import * as actions from './actions';
import createRootReducer from './reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history = createBrowserHistory();

const thumbprint = window.localStorage.getItem('thumbprint');
const userItem: string | null = window.localStorage.getItem('user');
const user = userItem && JSON.parse(userItem);

export default (): Store<any> => {
  const ext = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = ext || compose;

  const store = createStore(
    createRootReducer(history),
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history),
      ),
    ),
  );

  if (!userItem) return store;

  store.dispatch(actions.fetchUserDetails(user));
  store.dispatch(actions.loginSuccess({ user }));

  if (thumbprint) {
    store.dispatch(actions.setUserCertificate({ thumbprint }));
  }

  return store;
};
