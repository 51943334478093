import { schema } from 'normalizr';

export const companySchema = new schema.Entity('companies');

export const roleSchema = new schema.Entity('roles');

export const subdivisionSchema = new schema.Entity('subdivisions');

export const indicatorTypeSchema = new schema.Entity('indicatorTypes');

export const referenceSchema = new schema.Entity('references', {
  IndicatorType: indicatorTypeSchema,
});

export const riskGroupSchema = new schema.Entity('riskGroup', {
  References: [referenceSchema],
});

export const userSchema = new schema.Entity('users', {
  Subdivisions: [subdivisionSchema],
  RiskGroup: riskGroupSchema,
});

export const userListSchema = new schema.Array(userSchema);

export const venueSchema = new schema.Entity('venues');

export const contractSchema = new schema.Entity('contracts', {
  Contractor: companySchema,
  Customer: companySchema,
  Subdivisions: [subdivisionSchema],
  Nurses: [userSchema],
  Venues: [venueSchema],
  Manager: userSchema,
  Chiefs: [userSchema],
});

export const contractListSchema = new schema.Array(contractSchema);

export const loggedUserSchema = new schema.Entity('userInfo', {
  Subdivisions: [subdivisionSchema],
  NursingContracts: contractListSchema,
  ChiefContracts: contractListSchema,
  Roles: [roleSchema],
});

export const examTypeSchema = new schema.Entity('examTypes');

export const examSchema = new schema.Entity('exams', {
  Contract: contractSchema,
  Nurse: userSchema,
  Worker: userSchema,
  ExamType: examTypeSchema,
});

export const examListSchema = new schema.Array(examSchema);
