import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import * as actions from '../actions';

const currentCertificate = handleActions({
  [actions.setUserCertificateSuccess.toString()](_state, { payload: { thumbprint } }) {
    return { thumbprint };
  },
}, { thumbprint: null });

export default combineReducers({ currentCertificate });
