import { combineReducers } from 'redux';

import { journalPage } from './journalPage';
import { statisticPage } from './statisticPage';

export const ui = combineReducers({
  journalPage,
  statisticPage,
});

export default { ui };
