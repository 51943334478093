import { ThunderboltOutlined } from '@ant-design/icons';
import {
  Button, Col, List, Modal, Result, Row, Spin,
} from 'antd';
import { getUserCertificates } from 'crypto-pro';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import connect from '../../connect';

@connect(null)
class LoginByDSPage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      certificates: [],
      isCertsReceived: false,
      isModalVisible: false,
    };
  }

  handleGetCertificates = async () => {
    this.setState({
      isModalVisible: true,
    });

    try {
      const allCertificates = await getUserCertificates();
      const validityPromises = allCertificates.map((c) => c.isValid());
      const certsValities = await Promise.all(validityPromises);
      const validCerts = allCertificates.filter((cert, index) => certsValities[index]);
      const ownersPromises = validCerts.map((c) => c.getOwnerInfo());
      const owners = await Promise.all(ownersPromises);
      const issuersPromises = validCerts.map((c) => c.getIssuerInfo());
      const issuers = await Promise.all(issuersPromises);
      const certificates = validCerts.map((c, index) => (
        { ...c, owner: owners[index], issuer: issuers[index] }));

      this.setState({
        certificates,
        isCertsReceived: true,
      });
    } catch (error) {
      console.warn({ error });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  render() {
    const { certAuth } = this.props;
    const { isModalVisible, isCertsReceived, certificates } = this.state;
    return (
      <div>
        <Modal
          title="Выбор сертификата ключа проверки электронной подписи"
          visible={isModalVisible}
          footer={[]}
          onCancel={this.handleCancel}
        >
          {
          !isCertsReceived
            ? <Spin />
            : (
              <List
                dataSource={certificates}
                renderItem={(item: any, index) => {
                  console.log({ item });
                  const issuer = _.find(item.issuer, ['title', 'Удостоверяющий центр']);
                  const owner = _.find(item.owner, ['title', 'Владелец']);
                  const lastName = _.find(item.owner, ['title', 'Фамилия']);
                  const name = _.find(item.owner, ['title', 'Имя Отчество']);
                  const subject = (lastName && name) ? [lastName.description, name.description].join(' ') : item.subjectName;
                  return (
                    <List.Item
                      key={index}
                      className="cerificate-item"
                      onClick={() => {
                        certAuth({ thumbprint: item.thumbprint });
                      }}
                    >
                      <Col>
                        <Row><b>{item.name}</b></Row>
                        <Row>
                          Издатель:
                          &nbsp;
                          {issuer.description}
                        </Row>
                        <Row>
                          Кому выдан:
                          &nbsp;
                          {owner.description}
                        </Row>
                        <Row>
                          Субьект:
                          &nbsp;
                          {subject}
                        </Row>
                        <Row>
                          Действителен: с
                          &nbsp;
                          {moment(item.validFrom).format('DD.MM.YYYY')}
                          {' '}
                          по
                          &nbsp;
                          {moment(item.validTo).format('DD.MM.YYYY')}
                        </Row>
                      </Col>
                    </List.Item>
                  );
                }}
              />
            )
        }
        </Modal>
        <Result
          icon={<ThunderboltOutlined />}
          status="info"
          title="Присоедините к компьютеру носитель ключа электронной подписи"
          extra={(
            <Col>
              <Row gutter={[16, 16]} justify="center"><Button type="primary" onClick={this.handleGetCertificates}>Готово</Button></Row>
              <Row justify="center"><Link to="/login">Назад</Link></Row>
            </Col>
        )}
        />
      </div>
    );
  }
}

export default LoginByDSPage;
