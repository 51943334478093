import { createAction } from 'redux-actions';

export const setDateRangeRequest = createAction('REQUEST_SET_DATE_RANGE');
export const setDateRangeSuccess = createAction('SUCCESS_SET_DATE_RANGE');
export const setDateRangeFailure = createAction('FAILURE_SET_DATE_RANGE');

export const changeJournalData = createAction('JOURNAL_DATA_CHANGE');

export const setContract = createAction('JOURNAL_CONTRACT_SET');

export const setExamType = createAction('JOURNAL_EXAM_TYPE_SET');

export const setEmployeeFullName = createAction('JOURNAL_EMPLOYEE_FULLNAME_SET');
export const setDates = createAction('JOURNAL_DATES_SET');

export default setDates;
