import { createSelector } from 'reselect';

export function getContracts(state) {
  return state.contracts.entities.contracts ? state.contracts.entities.contracts : [];
}

export function getCompanies(state) {
  return state.contracts.entities.companies ? state.contracts.entities.companies : [];
}

export const contractsSelector = createSelector(
  getContracts,
  (contracts) => Object.values(contracts),
);


export const companiesSelector = createSelector(
  getCompanies,
  (companies) => Object.values(companies),
);
