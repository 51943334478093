import { companiesSelector, contractsSelector } from '@/selectors/contracts';
import employeesSelector from '@/selectors/employees';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import {
  Badge,
  Card,
  Col,
  DatePicker,
  Divider,
  List,
  Row,
  Skeleton,
  Statistic,
  Typography,
  Modal,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Pie } from 'react-chartjs-2';

import connect from '../../connect';
import selectors from '../../selectors';
import ExamsTable from '../ExamsTable';
import UsersTable from '../UsersTable';

const { Title } = Typography;

const dateFormat = 'DD/MM/YYYY';

const EXAM_TYPES = {
  PRETRIP: 1,
  AFTERTRIP: 2,
  PRESHIFT: 3,
  AFTERSHIFT: 4,
};

const mapStateToProps = (state) => {
  const {
    user,
    contracts: { entities: contractsEnts, fetchingContractsStatus },
    employees: { entities: employeesEnts, employeesFetchingStatus },
    exams: { fetchingExamsStatus },
    ui: { statisticPage: { date } },
  } = state;

  const isLoading = fetchingExamsStatus == 'requested';

  const startOfDay = moment(date).startOf('day');
  const endOfDay = moment(date).endOf('day');

  const getExamsGrouppedByContract = (filter = {}) => {
    const exams = selectors.exams(state, {
      filter: {
        status: ['signed'],
        startOfDay,
        endOfDay,
        ...filter,
      },
    });
    return _.groupBy(exams, 'Contract');
  };

  const getExams = (filter = {}) => ({
    byContract: getExamsGrouppedByContract(filter),
    all: _.chain(getExamsGrouppedByContract(filter))
      .transform((acc, values) => acc.push(values), [])
      .flatten()
      .value(),
  });

  const exams = {
    clear: getExams(),
    preTrip: {
      clear: getExams({ examTypeId: EXAM_TYPES.PRETRIP }),
      allowed: getExams({ examTypeId: EXAM_TYPES.PRETRIP, allowance: true }),
      notAllowed: getExams({ examTypeId: EXAM_TYPES.PRETRIP, allowance: false }),
    },
    afterTrip: {
      clear: getExams({ examTypeId: EXAM_TYPES.AFTERTRIP }),
      allowed: getExams({ examTypeId: EXAM_TYPES.AFTERTRIP, allowance: true }),
      notAllowed: getExams({ examTypeId: EXAM_TYPES.AFTERTRIP, allowance: false }),
    },
    preShift: {
      clear: getExams({ examTypeId: EXAM_TYPES.PRESHIFT }),
      allowed: getExams({ examTypeId: EXAM_TYPES.PRESHIFT, allowance: true }),
      notAllowed: getExams({ examTypeId: EXAM_TYPES.PRESHIFT, allowance: false }),
    },
    afterShift: {
      clear: getExams({ examTypeId: EXAM_TYPES.AFTERSHIFT }),
      allowed: getExams({ examTypeId: EXAM_TYPES.AFTERSHIFT, allowance: true }),
      notAllowed: getExams({ examTypeId: EXAM_TYPES.AFTERSHIFT, allowance: false }),
    },
  };

  const employees = _.transform(employeesEnts.users,
    (acc, usr) => {
      const subdivision = usr.Subdivisions[0];
      const contractId = _.findKey(
        contractsEnts.contracts,
        ({ Subdivisions }) => Subdivisions.includes(subdivision),
      );
      const result = (acc[contractId] || (acc[contractId] = [])).push(usr);
      return result;
    },
    {});

  return {
    user,
    isLoading,
    companies: contractsEnts.companies,
    contracts: contractsEnts.contracts,
    employees,
    exams,
    date,
  };
};

@connect(mapStateToProps) class StatisticPage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isExamsModalVisible: false,
      isEmployeesModalVisible: false,
      examsForModalShown: [],
      employeesForModalShown: [],
    };
  }

  handleShowExamsModal = (exams) => () => {
    this.setState({
      isExamsModalVisible: true,
      examsForModalShown: exams,
    });
  };

  handleShowEmployeesModal = (employees) => () => {
    console.log({ employees });
    this.setState({
      isEmployeesModalVisible: true,
      employeesForModalShown: employees,
    });
  };

  render() {
    const {
      contracts,
      companies,
      isLoading,
      exams,
      date,
      employees,

      setDate,
      fetchExams,
    } = this.props;

    const {
      isExamsModalVisible,
      examsForModalShown,
      isEmployeesModalVisible,
      employeesForModalShown,
    } = this.state;

    return isLoading
      ? <Skeleton />
      : (
        <>
          <Modal
            visible={isExamsModalVisible}
            width="100%"
            onCancel={() => this.setState({ isExamsModalVisible: false })}
            footer={null}
          >
            <ExamsTable filter={{ ids: _.map(examsForModalShown, 'id') }} />
          </Modal>
          <Modal
            visible={isEmployeesModalVisible}
            width="100%"
            onCancel={() => this.setState({ isEmployeesModalVisible: false })}
            footer={null}
          >
            <UsersTable filter={{ ids: _.map(employeesForModalShown, 'id') }} />
          </Modal>
          <Row>
            <DatePicker
              onChange={(d) => {
                const startOfDay = moment(d).startOf('day');
                const endOfDay = moment(d).endOf('day');
                fetchExams({ contracts: Object.values(contracts), startOfDay, endOfDay });
                setDate({ date: d });
              }}
              format={dateFormat}
              value={date}
            />
          </Row>
          <Row>
            <Col span={24}>
              <List
                itemLayout="vertical"
                size="default"
                pagination={{ responsive: true, position: 'bottom', defaultPageSize: 10 }}
                dataSource={Object.values(contracts || {})}
                renderItem={(contract: any) => {
                  const { id } = contract;

                  const customer = companies[contract.Customer];
                  const contractor = companies[contract.Contractor];
                  const currentEmployees = employees[id];

                  const employeesStats = {
                    preTrip: {
                      allowed: _.filter(currentEmployees,
                        (employee) => !!_.find(exams.preTrip.allowed.byContract[id],
                          (exam) => exam.Worker === employee.id)),
                      notAllowed: _.filter(currentEmployees,
                        (employee) => !!_.find(exams.preTrip.notAllowed.byContract[id],
                          (exam) => exam.Worker === employee.id)),
                    },
                    afterTrip: {
                      passed: _.filter(currentEmployees,
                        (employee) => !!_.find(exams.afterTrip.clear.byContract[id],
                          (exam) => exam.Worker === employee.id)),
                      notPassedAfter: [],
                    },
                    preShift: {
                      allowed: _.filter(currentEmployees,
                        (employee) => !!_.find(exams.preShift.allowed.byContract[id],
                          (exam) => exam.Worker === employee.id)),
                      notAllowed: _.filter(currentEmployees,
                        (employee) => !!_.find(exams.preShift.notAllowed.byContract[id],
                          (exam) => exam.Worker === employee.id)),
                    },
                    afterShift: {
                      passed: _.filter(currentEmployees,
                        (employee) => !!_.find(exams.afterShift.clear.byContract[id],
                          (exam) => exam.Worker === employee.id)),
                      notPassedAfter: [],
                    },
                  };

                  return (
                    <List.Item
                      key={id}
                    >
                      <List.Item.Meta
                        title={(
                          <Typography.Text mark>
                            {contract.name}
                          </Typography.Text>
                        )}
                        description={(
                          <div>
                            <Row>{['Заказчик:', customer.legalName].join(' ')}</Row>
                            <Row>{['Исполнитель:', contractor.legalName].join(' ')}</Row>

                            <Row>
                              <Typography.Text strong>Всего осмотров:</Typography.Text>
                              &nbsp;
                              <Badge
                                count={exams.clear.byContract[id]?.length}
                                showZero
                                style={{
                                  backgroundColor: '#fff',
                                  color: '#999',
                                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                                }}
                              />
                            </Row>
                          </div>
                        )}
                        style={{ marginTop: '35px' }}
                      />
                      <div>
                        <Row gutter={[24, 48]}>
                          <Col span={12}>
                            <Row gutter={[0, 0]}>
                              <Divider>
                                <Title level={4}>
                                  Предрейсовые медицинские осмотры
                                </Title>
                              </Divider>
                            </Row>
                            <Row gutter={[24, 48]} justify="space-between" align="middle">
                              <Col span={12}>
                                <Card size="small" title="Проведенные осмотры">
                                  <Row justify="space-around" align="middle">
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.preTrip.clear.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.preTrip.clear.byContract[id]?.length}
                                        title="Всего"
                                      />
                                    </Col>
                                    <Col onClick={
                                      this.handleShowExamsModal(
                                        exams.preTrip.allowed.byContract[id],
                                      )
                                    }
                                    >
                                      <Statistic
                                        value={
                                          exams.preTrip.allowed.byContract[id]?.length
                                        }
                                        title={<CheckCircleOutlined />}
                                      />
                                    </Col>
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.preTrip.notAllowed.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.preTrip.notAllowed.byContract[id]?.length}
                                        title={<StopOutlined />}
                                      />
                                    </Col>
                                    <Col>
                                      <Pie
                                        data={{
                                          labels: [
                                            'Red',
                                            'Blue',
                                          ],
                                          datasets: [{
                                            data: [
                                              exams.preTrip.allowed.byContract[id]?.length,
                                              exams.preTrip.notAllowed.byContract[id]?.length,
                                            ],
                                            backgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                            hoverBackgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                          }],
                                        }}
                                        height={65}
                                        width={65}
                                        options={{ maintainAspectRatio: false }}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                              <Col span={12}>
                                <Card size="small" title="Сотрудники, прошедшие осмотр">
                                  <Row justify="space-around" align="middle">
                                    <Col onClick={() => {
                                      if (employeesStats.preTrip.allowed.length === 0) return;
                                      this.handleShowEmployeesModal(
                                        employeesStats.preTrip.allowed,
                                      );
                                    }}
                                    >
                                      <Statistic
                                        value={employeesStats.preTrip.allowed.length}
                                        title={<CheckCircleOutlined />}
                                        suffix="чел."
                                      />
                                    </Col>
                                    <Col onClick={() => {
                                      if (employeesStats.preTrip.notAllowed.length === 0) return;
                                      this.handleShowEmployeesModal(
                                        employeesStats.preTrip.notAllowed,
                                      );
                                    }}
                                    >
                                      <Statistic
                                        value={employeesStats.preTrip.notAllowed.length}
                                        title={<StopOutlined />}
                                        suffix="чел."
                                      />
                                    </Col>
                                    <Col>
                                      <Pie
                                        data={{
                                          labels: [
                                            'Red',
                                            'Blue',
                                          ],
                                          datasets: [{
                                            data: [
                                              employeesStats.preTrip.allowed.length,
                                              employeesStats.preTrip.notAllowed.length,
                                            ],
                                            backgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                            hoverBackgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                          }],
                                        }}
                                        height={65}
                                        width={65}
                                        options={{ maintainAspectRatio: false }}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={12}>
                            <Row gutter={[0, 0]} align="top">
                              <Divider>
                                <Title level={4}>
                                  Послерейсовые медицинские осмотры
                                </Title>
                              </Divider>
                            </Row>
                            <Row gutter={[24, 48]} justify="space-between" align="middle">
                              <Col span={12}>
                                <Card size="small" title="Проведенные осмотры">
                                  <Row justify="space-around" align="middle">
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.afterTrip.allowed.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.afterTrip.allowed.byContract[id]?.length}
                                        title={<CheckCircleOutlined />}
                                      />
                                    </Col>
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.afterTrip.notAllowed.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.afterTrip.notAllowed.byContract[id]?.length}
                                        title={<StopOutlined />}
                                      />
                                    </Col>
                                    <Col>
                                      <Pie
                                        data={{
                                          labels: [
                                            'Red',
                                            'Blue',
                                          ],
                                          datasets: [{
                                            data: [
                                              exams.afterTrip.allowed.byContract[id]?.length,
                                              exams.afterTrip.notAllowed.byContract[id]?.length,
                                            ],
                                            backgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                            hoverBackgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                          }],
                                        }}
                                        height={65}
                                        width={65}
                                        options={{ maintainAspectRatio: false }}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                              <Col span={12}>
                                <Card size="small" title="Сотрудники, прошедшие осмотр">
                                  <Row justify="space-around" align="middle">
                                    <Col onClick={() => {
                                      if (employeesStats.afterTrip.passed.length === 0) return;
                                      this.handleShowEmployeesModal(
                                        employeesStats.afterTrip.passed,
                                      );
                                    }}
                                    >
                                      <Statistic
                                        value={employeesStats.afterTrip.passed.length}
                                        title="Прошли"
                                        suffix="чел."
                                      />

                                    </Col>
                                    <Col>
                                      <Statistic
                                        // value={employees.trip.after.notPassedAfter[id]}
                                        title="Еще не прошли"
                                        suffix="чел."
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row gutter={[24, 48]}>
                          <Col span={12}>
                            <Row gutter={[0, 0]} align="top"><Divider><Title level={4}>Предсменные медицинские осмотры</Title></Divider></Row>
                            <Row gutter={[24, 48]} justify="space-between" align="middle">
                              <Col span={12}>
                                <Card size="small" title="Проведенные осмотры">
                                  <Row justify="space-around" align="middle">
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.preShift.clear.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.preShift.clear.byContract[id]?.length}
                                        title="Всего"
                                      />
                                    </Col>
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.preShift.allowed.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.preShift.allowed.byContract[id]?.length}
                                        title={<CheckCircleOutlined />}
                                      />
                                    </Col>
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.preShift.notAllowed.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.preShift.notAllowed.byContract[id]?.length}
                                        title={<StopOutlined />}
                                      />
                                    </Col>
                                    <Col>
                                      <Pie
                                        data={{
                                          labels: [
                                            'Red',
                                            'Blue',
                                          ],
                                          datasets: [{
                                            data: [
                                              exams.preShift.allowed.byContract[id]?.length,
                                              exams.preShift.notAllowed.byContract[id]?.length,
                                            ],
                                            backgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                            hoverBackgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                          }],
                                        }}
                                        height={65}
                                        width={65}
                                        options={{ maintainAspectRatio: false }}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                              <Col span={12}>
                                <Card size="small" title="Сотрудники, прошедшие осмотр">
                                  <Row justify="space-around" align="middle">
                                    <Col onClick={() => {
                                      if (employeesStats.preShift.allowed.length === 0) return;
                                      this.handleShowEmployeesModal(
                                        employeesStats.preShift.allowed,
                                      );
                                    }}
                                    >
                                      <Statistic
                                        value={employeesStats.preShift.allowed.length}
                                        title={<CheckCircleOutlined />}
                                        suffix="чел."
                                      />
                                    </Col>
                                    <Col onClick={() => {
                                      if (employeesStats.preShift.notAllowed.length === 0) return;
                                      this.handleShowEmployeesModal(
                                        employeesStats.preShift.notAllowed,
                                      );
                                    }}
                                    >
                                      <Statistic
                                        value={employeesStats.preShift.notAllowed.length}
                                        title={<StopOutlined />}
                                        suffix="чел."
                                      />
                                    </Col>
                                    <Col>
                                      <Pie
                                        data={{
                                          labels: [
                                            'Red',
                                            'Blue',
                                          ],
                                          datasets: [{
                                            data: [
                                              employeesStats.preShift.allowed.length,
                                              employeesStats.preShift.notAllowed.length,
                                            ],
                                            backgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                            hoverBackgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                          }],
                                        }}
                                        height={65}
                                        width={65}
                                        options={{ maintainAspectRatio: false }}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={12}>
                            <Row gutter={[0, 0]} align="top">
                              <Divider>
                                <Title level={4}>
                                  Послесменные медицинские осмотры
                                </Title>
                              </Divider>
                            </Row>
                            <Row gutter={[24, 48]} justify="space-between" align="middle">
                              <Col span={12}>
                                <Card size="small" title="Проведенные осмотры">
                                  <Row justify="space-around" align="middle">
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.afterShift.allowed.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.afterShift.allowed.byContract[id]?.length}
                                        title={<CheckCircleOutlined />}
                                      />
                                    </Col>
                                    <Col
                                      onClick={
                                        this.handleShowExamsModal(
                                          exams.afterShift.notAllowed.byContract[id],
                                        )
                                      }
                                    >
                                      <Statistic
                                        value={exams.afterShift.notAllowed.byContract[id]?.length}
                                        title={<StopOutlined />}
                                      />
                                    </Col>
                                    <Col>
                                      <Pie
                                        data={{
                                          labels: [
                                            'Red',
                                            'Blue',
                                          ],
                                          datasets: [{
                                            data: [
                                              exams.afterShift.allowed.byContract[id]?.length,
                                              exams.afterShift.notAllowed.byContract[id]?.length,
                                            ],
                                            backgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                            hoverBackgroundColor: [
                                              '#FF6384',
                                              '#36A2EB',
                                            ],
                                          }],
                                        }}
                                        height={65}
                                        width={65}
                                        options={{ maintainAspectRatio: false }}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                              <Col span={12}>
                                <Card size="small" title="Сотрудники, прошедшие осмотр">
                                  <Row justify="space-around" align="middle">
                                    <Col onClick={() => {
                                      if (employeesStats.afterShift.passed.length === 0) return;
                                      this.handleShowEmployeesModal(
                                        employeesStats.afterShift.passed,
                                      )
                                    }
                                    }
                                    >
                                      <Statistic
                                        value={employeesStats.afterShift.passed.length}
                                        title="Прошли"
                                        suffix="чел."
                                      />

                                    </Col>
                                    <Col>
                                      <Statistic
                                        title="Еще не прошли"
                                        suffix="чел."
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>

                    </List.Item>
                  );
                }}
              />
            </Col>
          </Row>
        </>
      );
  }
}

export default StatisticPage;
