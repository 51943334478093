import {
  DesktopOutlined, PieChartOutlined, TableOutlined, UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React from 'react';
import _ from 'lodash';

import connect from '../../connect';
import * as selectors from '../../selectors';

const mapStateToProps = (state) => {
  const isAuthenticated = selectors.user.isAuthenticated(state);
  return {
    isAuthenticated,
    Roles: state.user.Roles,
  };
};

@connect(mapStateToProps)
class SideMenu extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const {
      isAuthenticated,
      Roles,
      push,
    } = this.props;

    const { collapsed } = this.state;
    const isNurse = _.includes(Roles, 2);

    return (!!isAuthenticated
      && (
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={this.onCollapse}
        theme="light"
      >
        <div className="logo" />
        <Menu
          theme="light"
          mode="inline"
          onSelect={({ key }: any) => push(key)}
        >
          {
            isNurse && (
            <Menu.Item key="/exams">
              <DesktopOutlined />
              <span>
                Осмотры
              </span>
            </Menu.Item>
            )
  }
          <Menu.Item key="/statistic">
            <PieChartOutlined />
            <span>Статистика</span>
          </Menu.Item>
          <Menu.Item key="/journal">
            <TableOutlined />
            <span>Журнал</span>
          </Menu.Item>
          <Menu.Item key="/employees">
            <UserOutlined />
            <span>Сотрудники</span>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      ));
  }
}

export default SideMenu;
