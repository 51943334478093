import { Col, Row, Skeleton } from 'antd';
import React from 'react';

import connect from '../../connect';
import UsersTable from '../UsersTable';

const mapsStateToProps = (state: any) => {
  const fetchingExamsStatus = state.exams;
  const loading = fetchingExamsStatus === 'requested';

  const { dateRange } = state.ui.journalPage;

  const props = {
    loading,
    user: state.user,
    dateRange,
  };
  return props;
};

@connect(mapsStateToProps) class EmployeesPage extends React.Component<any, any> {
  render() {
    const {
      loading,
    } = this.props;

    if (loading) return <Skeleton active />;

    return (
      <div style={{ backgroundColor: 'white' }}>
        <Row style={{ marginTop: '10px' }}>
          <Col span={24}>
            <UsersTable />
          </Col>
        </Row>

      </div>
    );
  }
}

export default EmployeesPage;
